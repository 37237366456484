// src/axiosInstance.js
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://testnew-production-a330.up.railway.app/api', // replace with your actual base URL
  // baseURL: 'http://localhost:5000/api', // replace with your actual base URL

});

export default axiosInstance;
