import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import axios from '../../axiosInstance';
import "react-notifications/lib/notifications.css";
import { NotificationContainer, NotificationManager } from 'react-notifications';

const eye = <FontAwesomeIcon icon={faEye} />;

const ResetPassword = () => {
  const { userId } = useParams();
  const [password, setPassword] = useState('');
  const [cPassword, setCPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [cPasswordShown, setCPasswordShown] = useState(false);

  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleCPasswordChange = (e) => setCPassword(e.target.value);
  const togglePasswordVisibility = () => setPasswordShown(!passwordShown);
  const toggleCPasswordVisibility = () => setCPasswordShown(!cPasswordShown);

  const submitNewPassword = () => {
    if (password !== cPassword) {
      NotificationManager.warning("Passwords don't match!", 'Warning');
    } else {
      axios.post('/users/newpass', { userId, password })
        .then(res => {
          NotificationManager.success(res.data, 'Success');
          window.location = "/login";
        })
        .catch(err => {
          NotificationManager.error("Failed to reset password", 'Error');
        });
    }
  };

  return (
    <div>
      <NotificationContainer />
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="card text-left py-5 px-5 px-sm-5" style={{ backgroundColor: '#1a1a34' }}>
              <h4 className='mb-4'>Please input your new Password</h4>
              <div className='row'>
                <div className="form-group col-12">
                  <div className="input-group">
                    {passwordShown ? (
                      <input type="text" value={password} style={{ color: 'white', backgroundColor: 'rgb(26, 26, 52)' }} className="form-control form-control-lg" onChange={handlePasswordChange} placeholder="Password" />
                    ) : (
                      <input type="password" value={password} style={{ color: 'white', backgroundColor: 'rgb(26, 26, 52)' }} className="form-control form-control-lg" onChange={handlePasswordChange} placeholder="Password" />
                    )}
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i onClick={togglePasswordVisibility} style={{ cursor: 'pointer', zIndex: '1', color: '#d1d1d1' }}>{eye}</i></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className="form-group col-12">
                  <div className="input-group">
                    {cPasswordShown ? (
                      <input type="text" value={cPassword} style={{ color: 'white', backgroundColor: 'rgb(26, 26, 52)' }} className="form-control form-control-lg" onChange={handleCPasswordChange} placeholder="Confirm Password" />
                    ) : (
                      <input type="password" value={cPassword} style={{ color: 'white', backgroundColor: 'rgb(26, 26, 52)' }} className="form-control form-control-lg" onChange={handleCPasswordChange} placeholder="Confirm Password" />
                    )}
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i onClick={toggleCPasswordVisibility} style={{ cursor: 'pointer', zIndex: '1', color: '#d1d1d1' }}>{eye}</i></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" onClick={submitNewPassword}>Confirm</button>
              </div>
              <div className="text-center mt-4 font-weight-light">
                Please go on Login&nbsp;&nbsp;&nbsp;&nbsp; <Link to="/login" className="text-primary">Login</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;